import React from "react";
// LD Schemsas
import schemaHWP from "../../content/ld/products/hwp.json";
import schemaContacts from "../../content/ld/contacts.json";
import schemaKid from "../../content/ld/products/kid.json";
import schemaLFE from "../../content/ld/products/lfe.json";
import schemaCI from "../../content/ld/products/ci.json";
import schemaVigCheck from "../../content/ld/products/vigCheck.json";
import schemaGLO from "../../content/ld/products/glo.json";
import schemaGTPCheck from "../../content/ld/products/gtpCheck.json";
import schemaHIS from "../../content/ld/products/his.json";
import schemaBol from "../../content/ld/products/bol.json";
// import schemaLfi from "../../content/ld/products/lfi.json";
import schemaLfeCategory from "../../content/ld/life-category.json";

import Logo from "../../images/logo-new.svg";
import ProductsService from "../../services/ProductsService";
import MTNIcon from "../../images/boleron/landing/mtn.svg";
import EXTIcon from "../../images/boleron/landing/ext.svg";
import HLTIcon from "../../images/boleron/landing/hlt.svg";
import LFEIcon from "../../images/boleron/landing/lfe.svg";
import LFIIcon from "../../images/boleron/landing/lfi.svg";
import CIIcon from "../../images/boleron/landing/ci.svg";
import HWPIcon from "../../images/boleron/landing/hwp.svg";
import HCLIcon from "../../images/boleron/landing/hcl.svg";
import GOIcon from "../../images/boleron/landing/go.svg";
import CSCIcon from "../../images/boleron/landing/csc.svg";
import TRAIcon from "../../images/boleron/landing/tra.svg";
import PRPIcon from "../../images/boleron/landing/prp.svg";
import CSCGOIcon from "../../images/boleron/landing/cscgo.svg";
import GLOIcon from "../../images/boleron/landing/glo.svg";
import VigIcon from "../../images/boleron/landing/vig.svg";
import BuyVigIcon from "../../images/boleron/landing/buyvig.svg";
import ChrIcon from "../../images/boleron/landing/crh.svg";
import CHCLIcon from "../../images/boleron/landing/chcl.svg";
import HCEFIcon from "../../images/boleron/landing/hcef.svg";
import GTPICON from "../../images/boleron/landing/gtp.svg";

const autoProducts = [
  {
    id: "CSC",
    title: language => ProductsService.getProductName("CSC", language),
    price: "Compare prices and save up to 30%",
    link: "/kasko-kalkulator/",
    // Сравни оферти
    checklist: ["Choice of 8 insurers", "Automatic market valuation", "You get the policy right away"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    offlineOffer: true,
    insurers: [
      {
        id: "dzi",
        hasPromoCode: true,
        hasPrice: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_DZI.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_DZI.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_DZI_OU.pdf"
          }
        ]
      },
      {
        id: "armeec",
        hasPrice: true,
        hasPromoCode: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_ARMEEC.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_ARMEEC.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_ARMEEC_OU.pdf"
          }
        ]
      },
      {
        id: "bulins",
        hasPrice: true,
        hasPromoCode: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_BULINS.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_BULINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULINS_OU.pdf"
          }
        ]
      },
      {
        id: "bulinsHit",
        hasPrice: true,
        hasPromoCode: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_BULINS.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_BULINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULINS_OU.pdf"
          }
        ]
      },
      {
        id: "bulstrad",
        hasPrice: true,
        hasPromoCode: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_OU.PDF"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/BULSTRAD_PDOG.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPromoCode: true,
        hasPrice: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_EUROINS_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_EUROINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_EUROINS_OU.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_EUROINS.pdf"
          }
        ]
      },
      {
        id: "generali",
        hasPromoCode: true,
        hasPrice: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_GENERALI.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_GENERALI.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_GENERALI_OU.pdf"
          }
        ]
      },
      {
        id: "levins",
        hasPrice: true,
        hasPromoCode: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_OU.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "GO",
    title: language => ProductsService.getProductName("GO", language),
    price: "Compare prices and save",
    link: "/grazhdanska-otgovornost-online/",
    checklist: ["Choice of 10 insurers", "See prices in just 30 seconds", "Free Shipping"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "allianz",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          }
        ] // todo за армеец
      },
      {
        id: "armeec",
        disabled: true,
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          }
        ] // todo to check helpUrl, euDocumentUrl
      },
      {
        id: "bulins",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_BULINS_A3.pdf"
          }
        ]
      },
      {
        id: "bulstrad",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrlGO_BULSTRAD",
            label: "ЗЕАД Булстрад Виена Иншурънс Груп",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_BULSTRAD_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_UNIQA",
            label: "ЗК УНИКА АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_UNIQA_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_EUROINS",
            label: "ЗД Евроинс АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_EUROINS_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_OZK",
            label: "ЗАД ОЗК Застраховане АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_OZK_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_BULINS",
            label: "ЗД Булинс АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_BULINS_A3.pdf"
          },

          {
            id: "euDocumentUrlGO_DALBOGG",
            label: "ЗАД Далл Богг: Живот и Здраве АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_DALBOGG_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_GENERALI",
            label: "Дженерали Застраховане АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_GENERALI_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_DZI",
            label: "ДЗИ - Общо застраховане ЕАД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_DZI_A3.pdf"
          },
          {
            id: "euDocumentUrlCSC_OU_ALLIANZ",
            label: "ЗАД Алианц България",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_OU_ALLIANZ.pdf"
          },
          {
            id: "euDocumentUrlGO_ARMEEC",
            label: "ЗАД Армеец АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_ARMEEC_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_LEVINS",
            name: "ЗК Лев Инс АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_LEVINS_A3.pdf"
          }
        ]
      },
      {
        id: "dallbogg",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_DALLBOGG_A3.pdf"
          }
        ]
      },
      {
        id: "dzi",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_DZI_A3.pdf"
          }
        ]
      },
      {
        id: "euroins",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_EUROINS_A3.pdf"
          }
        ]
      },
      {
        id: "generali",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_GENERALI_A3.pdf"
          }
        ]
      },
      {
        id: "levins",
        paymentModes: ["1", "2"],
        documents: [
          { id: "helpUrl", label: "", url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf" },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_LEVINS_A3.pdf"
          }
        ]
      },
      {
        id: "ozk",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_OZK_A3.pdf"
          }
        ]
      },
      {
        id: "uniqa",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_UNIQA_A3.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "CSC+GO",
    disabled: false, // todo to remove it
    title: language => ProductsService.getProductName("CSC+GO", language),
    price: "Compare prices and save up to 50%",
    link: "/kasko-plus-grazhdanska/",
    checklist: ["Combine and save", "Extra discount", "Free Shipping"],
    couriers: ["speedy", "econt"],
    successfulPurchaseMessage: "",
    offlineOffer: true,
    insurers: [
      {
        id: "allianz",
        hasPrice: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_ALLIANZ_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_OU_ALLIANZ.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_ALLIANZ.pdf"
          }
        ]
      },
      {
        id: "armeec",
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_ARMEEC.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_ARMEEC.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_ARMEEC_OU.pdf"
          }
        ]
      },
      {
        id: "bulins",
        hasPrice: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_BULINS.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_BULINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULINS_OU.pdf"
          }
        ]
      },
      {
        id: "bulstrad",
        hasPrice: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_OU.PDF"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/BULSTRAD_PDOG.pdf"
          }
        ]
      },
      {
        id: "dzi",
        hasPrice: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_DZI.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_DZI.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_DZI_OU.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPrice: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_EUROINS_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_EUROINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_EUROINS_OU.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_EUROINS.pdf"
          }
        ]
      },
      {
        id: "generali",
        hasPrice: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_GENERALI.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_GENERALI.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_GENERALI_OU.pdf"
          }
        ]
      },
      {
        id: "levins",
        hasPrice: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_OU.pdf"
          }
        ]
      }
    ]
  }
];

const mainProducts = [
  ...autoProducts,

  {
    id: "TRA",
    title: language => ProductsService.getProductName("TRA", language),
    price: "Prices from BGN 2.00 per day",
    link: "/zastrahovka-patuvane-v-chuzhbina/",
    checklist: ["Covers treatment up to €50,000", "You get the policy right away", "24/7 worldwide support"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "levins",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_LEVINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_LEVINS.pdf"
          },
          { id: "predogUrl", label: "Преддоговорна информация", url: "#" },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_LEVINS.pdf"
          }
        ]
      },
      {
        id: "uniqa",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_UNIQA.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_UNIQA.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_PREDOG.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_UNIQA.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          }
        ]
      },
      {
        id: "allianz",
        hasPromoCode: true,
        documents: [
          {
            id: "termsUrl",
            label: "Общи условия на продукт при покритие до 10 000 евро",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_ALLIANZ_BALANCE.pdf"
          },
          {
            id: "termsUrl10000",
            label: "Общи условия на продукт при покритие до 10 000 евро",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_ALLIANZ_BALANCE.pdf"
          },
          {
            id: "termsUrl30000",
            label: "Общи условия на продукт при покритие до 30 000 евро",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_ALLIANZ_CLASSIC.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_ALLIANZ_BALANCE.pdf"
          },
          {
            id: "euDocumentUrl10000",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_ALLIANZ_BALANCE.pdf"
          },
          {
            id: "euDocumentUrl30000",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_ALLIANZ_CLASSIC.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_ALLIANZ_PREDOG.pdf"
          },
          {
            id: "privacyNoticeUrl",
            label: "Съобщение за поверителността на личните данни",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_ALLIANZ_PRIVACY_NOTECE_2020.pdf"
          },
          {
            id: "helpUrl",
            label: "Полезна информация при възникване на застрахователно събитие",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_ALLIANZ.pdf"
          }
        ]
      },
      {
        id: "bulstrad",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_BULSTRAD.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_BULSTRAD.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_BULSTRAD.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_BULSTRAD.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPromoCode: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_EUROINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_EUROINS.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_EUROINS.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_EUROINS.pdf"
          }
        ]
      },
      {
        id: "instinct",
        hasPromoCode: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_INSTINCT.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_INSTINCT.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_INSTINCT.pdf"
          },
          {
            id: "userInsuranceInfo",
            label: "Информация за ползвателя на застрахователни услуги",
            url: "https://devdiag558.blob.core.windows.net/documents/INFO_USER_INS.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/INSTINCT_UVP.pdf "
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_INSTINCT.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "PRP",
    title: language => ProductsService.getProductName("PRP", language),
    price: "Prices from BGN 10 per month",
    link: "/zastrahovka-imushtestvo/",
    checklist: ["Fire, flood, earthquake", "Automatic monthly payment", "Completely online, paperless"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "uniqa",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_A3_20191408.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_OU_20191408.pdf"
          },
          {
            id: "additionalTermsUrl",
            label: "Специални условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_SU_20191408.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_PREDOG.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_HELP_UNIQA.pdf"
          },
          {
            id: "assistanceTermsUrl",
            label: 'Клауза "Домашен асистанс"',
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_UNIQA_HOME_ASSISTANT.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          },
          {
            id: "goTermsUrl",
            label: 'ОУ "Обща Гражданска Отговорност"',
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_UNIQA_OU_OGO.pdf"
          } // само ако е избран чека в допълнителни покрития
        ]
      },
      {
        id: "bulstrad",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_BULSTRAD_BONUS_DOM+_A3.pdf "
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: " https://devdiag558.blob.core.windows.net/documents/PRP_BULSTRAD_BONUS_DOM+_OU.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_HELP_BULSTRAD.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "MTN",
    title: language => ProductsService.getProductName("MTN", language),
    price: "Prices from BGN 4 per month",
    link: "/planinska-zastrahovka/",
    checklist: ["Rescue and medical expenses", "Completely online, paperless", "You get the policy right away"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "uniqa",
        hasPromoCode: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_A3_20191408.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_OU_20191408.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_LIFE_PREDOG.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_HELP_UNIQA.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_EUROINS_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_EUROINS_OU.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_HELP_EUROINS.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_EUROINS.pdf"
          }
        ]
      },
      {
        id: "bulstrad",
        //   disabled: true,
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_BULSTRAD_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_BULSTRAD_OU.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при застрахователно събитие",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_HELP_BULSTRAD.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/BULSTRAD_PDOG.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "EXT",
    title: language => ProductsService.getProductName("EXT", language),
    price: "Mountain biking, paragliding, off-piste skiing",
    link: "/zastrahovka-ekstremni-sportove/",
    checklist: ["Rescue and medical expenses", "Completely online, paperless", "You get the policy right away"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "uniqa",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_A3_20191408.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_OU_20191408.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_LIFE_PREDOG.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/EXT_HELP_UNIQA.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_EUROINS_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_EUROINS_OU.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/EXT_HELP_EUROINS.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "LifeProducts",
    title: lng => ProductsService.getProductName("LifeProducts", lng),
    checklist: ["pages.common.LFE", "pages.common.LFI"],
    link: "/zastrahovki-zhivot",
    isHiddenInMainProducts: false,
    callToAction: "pages.common.seeMore"
  },
  {
    id: "HealthProducts",
    title: lng => ProductsService.getProductName("HealthProducts", lng),
    checklist: ["pages.common.healthIns1", "pages.common.healthIns2", "pages.common.healthIns3"],
    link: "/zdravni-zastrahovki",
    isHiddenInMainProducts: false,
    callToAction: "pages.common.seeMore"
  },
  {
    id: "CompanyProducts",
    title: lng => ProductsService.getProductName("CompanyProducts", lng),
    checklist: ["pages.common.companyIns1", "pages.common.companyIns2", "pages.common.companyIns3"],
    link: "/firmeni-zastrahovki",
    isHiddenInMainProducts: false,
    callToAction: "pages.common.seeMore"
  },
  {
    id: "AutoProducts",
    title: lng => ProductsService.getProductName("AutoProducts", lng),
    link: "/avtomobilni-zastrahovki",
    isHiddenInMainProducts: true
  },
  {
    id: "ChieldProducts",
    title: lng => ProductsService.getProductName("ChieldProducts", lng),
    link: "/detska-zastrahovka",
    isHiddenInMainProducts: true
  }
];

const companyProducts = [
  {
    id: "HCEF",
    disabled: false,
    title: language => ProductsService.getProductName("HCEF", language),
    price: "Prices from BGN 60.95 per month for worker",
    link: "/dopulnitelno-zdravno-bulstrad/",
    contentProduct: "",
    checklist: ["Additional health insurance for businesses", "Expenses upto 10 000 BGN", "100% online, no paper"],

    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "bulstradlife",
        hasPromoCode: true,
        documents: [
          { id: "termsUrl", label: "Общи условия на продукта", url: "https://devdiag558.blob.core.windows.net/documents/HCEF_BULSTRAD_OU.pdf" },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/HCEF_BULSTRAD_INFODOC.pdf"
          },
          {
            id: "userInsuranceInfo",
            label: "Информация за ползвателя на застрахователни услуги",
            url: "https://devdiag558.blob.core.windows.net/documents/INFO_USER_INS.pdf"
          }
        ]
      }
    ]
  },

  {
    id: "CHCL",
    disabled: false,
    title: language => (language === "en" ? "Health insurance for buisness" : "Фирмена здравна застраховка"), //  ProductsService.getProductName("CHCL", language),
    price: "Prices from 120 BGN per employee",
    link: "/zdravna-zastrahovka-health-club/",
    contentProduct: "",
    checklist: ["Up to 1 million euro for medication", "No medical examination", "Completely online, paperless"],

    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "bulstradlife",
        hasPromoCode: true,
        documents: [
          { id: "termsUrl", label: "Общи условия на продукта", url: "https://devdiag558.blob.core.windows.net/documents/HCL_BULSTRAD_OU.pdf" },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/HCL_BULSTRAD_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/HCL_BULSTRAD_INFODOC.pdf"
          },
          {
            id: "userInsuranceInfo",
            label: "Информация за ползвателя на застрахователни услуги",
            url: "https://devdiag558.blob.core.windows.net/documents/INFO_USER_INS.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "CI",
    disabled: false,
    title: language => ProductsService.getProductName("CI", language),
    price: "Prices from BGN 50 per month for worker",
    link: "/firmena-zastrahovka-zhivot/",
    contentProduct: "",
    checklist: ["100 000 BGN life insurance", "50 000 BGN serious illness", "100 BGN per day for hospital care"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "metlife",
        hasPromoCode: true,
        documents: [{ id: "termsUrl", label: "Общи условия на продукта", url: "https://devdiag558.blob.core.windows.net/documents/CI_METLIFE_OU.pdf" }]
      }
    ]
  }
];

const lifeProducts = [
  {
    id: "LFE",
    disabled: false,
    title: language => ProductsService.getProductName("LFE", language),
    price: "Prices from BGN 50 per month",
    link: "/zastrahovka-jivot/",
    contentProduct: "",
    checklist: ["Up to 400,000 Euro", "No medical examination", "Completely online, paperless"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "metlife",
        hasPromoCode: true,
        documents: [
          { id: "termsUrl", label: "Общи условия на продукта", url: "https://devdiag558.blob.core.windows.net/documents/LIFE_METLIFE_TERM_OU.pdf" },
          { id: "helpUrl", label: "Полезна информация при възникване на застрахователно събитие", url: "https://devdiag558.blob.core.windows.net/documents/LIFE_METLIFE_HELP.pdf" }
        ]
      }
    ]
  },
  {
    id: "LFI",
    disabled: false,
    title: language => ProductsService.getProductName("LFI", language),
    price: "Annual return up to 12%",
    link: "/zastrahovka-zhivot-investitsionen-fond/",
    contentProduct: "",
    checklist: ["Up to 350,000 BGN Life insurance", "No medical examination", "Completely online, paperless"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "metlife",
        hasPromoCode: true,
        documents: [
          { id: "termsUrl", label: "Общи условия на продукта", url: "https://devdiag558.blob.core.windows.net/documents/LFI_METLIFE_OU.pdf" },
          { id: "helpUrl", label: "Полезна информация при възникване на застрахователно събитие", url: "https://devdiag558.blob.core.windows.net/documents/LIFE_METLIFE_HELP.pdf" },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/LFI_METLIFE_INFODOC.pdf"
          }
        ]
      }
    ]
  }
];

const healthProducts = [
  {
    id: "HWP",
    disabled: false,
    paymentModes: ["1"],
    title: language => ProductsService.getProductName("HWP", language),
    price: "for treatment of serious illnesses abroad - price BGN 81.63 per month",
    link: "/best-doctors/",
    contentProduct: "",
    checklist: ["The best hospitals", "100,000 for medicine", "Up to 4,000,000 for treatment"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "metlife",
        hasPromoCode: true,
        documents: [{ id: "termsUrl", label: "Общи условия на продукта", url: "https://devdiag558.blob.core.windows.net/documents/HLT_METLIFE_TERM_OU.pdf" }]
      }
    ]
  },
  {
    id: "HCL",
    disabled: false,
    title: language => ProductsService.getProductName("HCL", language),
    price: "Prices from BGN 100 per month",
    link: "/zdravna-zastrahovka-health-club/",
    contentProduct: "",
    checklist: ["Up to 1 million euro for medication", "No medical examination", "Completely online, paperless"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "bulstradlife",
        hasPromoCode: true,
        documents: [
          { id: "termsUrl", label: "Общи условия на продукта", url: "https://devdiag558.blob.core.windows.net/documents/HCL_BULSTRAD_OU.pdf" },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/HCL_BULSTRAD_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/HCL_BULSTRAD_INFODOC.pdf"
          },
          {
            id: "userInsuranceInfo",
            label: "Информация за ползвателя на застрахователни услуги",
            url: "https://devdiag558.blob.core.windows.net/documents/INFO_USER_INS.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "HLT",
    title: language => ProductsService.getProductName("HLT", language),
    price: "prices from BGN 7.96 per month",
    link: "/zastrahovka-bolnichen-prestoi/",
    checklist: ["Hospital stay costs", "No medical examination", "Completely online, paperless"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "uniqa",
        hasPromoCode: true,
        documents: [
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/BOL_OU_20192409.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/BOL_A3_20191104.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_LIFE_PREDOG.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          },
          {
            id: "schemaUrl",
            label: "Схема на УНИКА за категориите операции",
            url: "https://devdiag558.blob.core.windows.net/documents/BOL_OP_SCHEMA.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "TRA",
    title: language => ProductsService.getProductName("TRA", language),
    price: "Prices from BGN 2.00 per day",
    link: "/zastrahovka-patuvane-v-chuzhbina/",
    checklist: ["Covers treatment up to €50,000", "You get the policy right away", "24/7 worldwide support"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "levins",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_LEVINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_LEVINS.pdf"
          },
          { id: "predogUrl", label: "Преддоговорна информация", url: "#" },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_LEVINS.pdf"
          }
        ]
      },
      {
        id: "uniqa",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_UNIQA.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_UNIQA.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_PREDOG.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_UNIQA.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          }
        ]
      },
      {
        id: "allianz",
        hasPromoCode: true,
        documents: [
          {
            id: "termsUrl",
            label: "Общи условия на продукт при покритие до 10 000 евро",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_ALLIANZ_BALANCE.pdf"
          },
          {
            id: "termsUrl10000",
            label: "Общи условия на продукт при покритие до 10 000 евро",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_ALLIANZ_BALANCE.pdf"
          },
          {
            id: "termsUrl30000",
            label: "Общи условия на продукт при покритие до 30 000 евро",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_ALLIANZ_CLASSIC.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_ALLIANZ_BALANCE.pdf"
          },
          {
            id: "euDocumentUrl10000",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_ALLIANZ_BALANCE.pdf"
          },
          {
            id: "euDocumentUrl30000",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_ALLIANZ_CLASSIC.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_ALLIANZ_PREDOG.pdf"
          },
          {
            id: "privacyNoticeUrl",
            label: "Съобщение за поверителността на личните данни",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_ALLIANZ_PRIVACY_NOTECE_2020.pdf"
          },
          {
            id: "helpUrl",
            label: "Полезна информация при възникване на застрахователно събитие",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_ALLIANZ.pdf"
          }
        ]
      },
      {
        id: "bulstrad",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_BULSTRAD.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_BULSTRAD.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_BULSTRAD.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_BULSTRAD.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPromoCode: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_EUROINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_EUROINS.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_EUROINS.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_EUROINS.pdf"
          }
        ]
      },
      {
        id: "instinct",
        hasPromoCode: true,
        paymentModes: ["1"],
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_INSTINCT.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_INSTINCT.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_INSTINCT.pdf"
          },
          {
            id: "userInsuranceInfo",
            label: "Информация за ползвателя на застрахователни услуги",
            url: "https://devdiag558.blob.core.windows.net/documents/INFO_USER_INS.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/INSTINCT_UVP.pdf "
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_INSTINCT.pdf"
          }
        ]
      }
    ]
  }
];

const onlineProducts = [
  {
    id: "KLE",
    disabled: true,
    title: language => ProductsService.getProductName("KLE", language),
    price: "Жилищни и фирмени кредити",
    link: "http://btconsult.bg?utm_source=boleron&utm_medium=affiliate&utm_campaign=boleron_first_page",
    checklist: ["Изгодни условия", "Оферти от всички банки", "Безплатна услуга"],
    callToAction: "Get the Offer"
  },
  {
    id: "VIG",
    title: language => ProductsService.getProductName("VIG", language),
    price: "",
    contentProduct: "With us you can quickly and easily buy",
    link: "/workflow/init-workflow?product=VIG"
  },
  {
    id: "VIGCHECK",
    title: language => ProductsService.getProductName("VIGCHECK", language),
    price: "",
    contentProduct: "Check the validity of an e-vignette for your vehicle - in just a few seconds.",
    link: "/proverka-elektronna-vinetka/",
    callToAction: "Check"
  },
  {
    id: "GLO",
    title: language => ProductsService.getProductName("GLO", language),
    price: " ",
    link: "/proverka-globi-kat-grazhdanska/",
    checklist: ["Civil Liability", "Vignette and fines from cat", "Technical Review"],
    callToAction: "Check"
  },
  {
    id: "GOCHECK",
    title: language => ProductsService.getProductName("GOCHECK", language),
    price: "",
    contentProduct: "Drive responsibly by making sure your third party liability insurance is valid.",
    link: "/proverka-grazhdanska/",
    callToAction: "Check"
  },
  {
    id: "GTPCHECK",
    title: language => ProductsService.getProductName("GTPCHECK", language),
    price: "",
    contentProduct: "Check quickly and easily just by entering the vehicle registration number.",
    link: "/proverka-tehnicheski-pregled/",
    callToAction: "Check"
  },
  {
    id: "CRH",
    title: language => ProductsService.getProductName("CRH", language),
    price: "",
    contentProduct: "Check the history of your vehicle by entering the VIN number or receipt number",
    link: "/vehicle-history",
    callToAction: "Check"
  }
];
const header = {
  logo: <Logo style={{ width: 170 }} />,
  logoText: {
    disabled: false
  },
  links: [
    { id: "home", url: "/" },
    { id: "insurances", url: "" },
    { id: "services", disabled: false },
    { id: "aboutUs", url: "/about-us", disabled: false },
    { id: "contacts", url: "/contacts", disabled: false },
    { id: "myProfile", url: "/policies", disabled: false },
    { id: "blog", url: "/blog", disabled: false },
    { id: "bottomLogo", url: "/", disabled: true }
  ],
  downloadApp: {
    disabled: false
  },
  hr: {
    disabled: true
  }
};

const marketingText = "Съгласен ли си да получаваш от Boleron оферти, намаления, промоции и подаръци?";

const footer = {
  logo: <Logo className="logo-footer" />,
  logoText: {
    disabled: false
  },
  sectionMenu: {
    disabled: false
  },
  sectionMessage: {
    disabled: false
  },
  sectionSocialMedia: {
    disabled: false
  },
  phone: {
    text: "0700 500 90"
  },
  email: {
    text: "office@boleron.com"
  },
  hr: {
    disabled: true
  },
  footerText: {
    isCentered: true,
    paddingStyles: "px-2"
  }
};

const productImages = [
  {
    product: "CSC+GO",
    image: (
      <div className="boleron-index-icon-wrapper">
        <CSCGOIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "GO",
    image: (
      <div className="boleron-index-icon-wrapper">
        <GOIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "CSC",
    image: (
      <div className="boleron-index-icon-wrapper">
        <CSCIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "TRA",
    image: (
      <div className="boleron-index-icon-wrapper">
        <TRAIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "PRP",
    image: (
      <div className="boleron-index-icon-wrapper">
        <PRPIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "MTN",
    image: (
      <div className="boleron-index-icon-wrapper">
        <MTNIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "EXT",
    image: (
      <div className="boleron-index-icon-wrapper">
        <EXTIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "LFE",
    image: (
      <div className="boleron-index-icon-wrapper">
        <LFEIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "LFI",
    image: (
      <div className="boleron-index-icon-wrapper">
        <LFIIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "HWP",
    image: (
      <div className="boleron-index-icon-wrapper">
        <HWPIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "HCL",
    image: (
      <div className="boleron-index-icon-wrapper">
        <HCLIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "HLT",
    image: (
      <div className="boleron-index-icon-wrapper">
        <HLTIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "CI",
    image: (
      <div className="boleron-index-icon-wrapper">
        <CIIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "HCEF",
    image: (
      <div className="boleron-index-icon-wrapper">
        <HCEFIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "GLO",
    image: (
      <div className="boleron-index-icon-wrapper">
        <GLOIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "VIG",
    image: (
      <div className="boleron-index-icon-wrapper">
        <BuyVigIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "VIGCHECK",
    image: (
      <div className="boleron-index-icon-wrapper">
        <VigIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "CRH",
    image: (
      <div className="boleron-index-icon-wrapper">
        <ChrIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "CHCL",
    image: (
      <div className="boleron-index-icon-wrapper">
        <CHCLIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "LifeProducts",
    image: (
      <div className="boleron-index-icon-wrapper">
        <LFEIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "HealthProducts",
    image: (
      <div className="boleron-index-icon-wrapper">
        <HLTIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "CompanyProducts",
    image: (
      <div className="boleron-index-icon-wrapper">
        <CIIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "GOCHECK",
    image: (
      <div className="boleron-index-icon-wrapper">
        <GOIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "GTPCHECK",
    image: (
      <div className="boleron-index-icon-wrapper">
        <GTPICON className="boleron-index-icon" />
      </div>
    )
  }
];

const tenantSettings = {
  companyName: "Boleron",
  couriers: ["speedy", "econt"],
  defaultSEO: {
    title: "BOLERON",
    description: "Онлайн застраховки",
    lang: "bg"
  },
  showMetaDataAndSchemas: true,
  companyURL: "https://www.boleron.bg/",
  isAppleGooglePayActive: true,
  pagesSEO: t => [
    {
      pageName: "/",
      title: t("onlineInsuranceTitle", "Онлайн застраховки - бързо, лесно и сигурно застраховане | Boleron"),
      description: t("onlineInsuranceDescription", "Онлайн застраховки с калкулатор от Boleron. Купи всички застраховки онлайн, получи полицата веднага по имейл, бързо и лесно."),
      keywords: t("onlineInsuranceKeywords", "онлайн застраховки, онлайн застраховане, мобилно приложение за застраховане, дигитално застраховане, болерон, boleron"),
      canonical: "/",
      image: "product/product_card_main.jpg"
    },
    {
      pageName: "/grazhdanska-otgovornost-online/",
      title: t("thirdPartyLiabilityInsuranceTitle", "Застраховка гражданска отговорност: Купи онлайн | Boleron"),
      description: t(
        "thirdPartyLiabilityInsuranceDescription",
        "Сравнете цените на застрахователите с нашия калкулатор и поръчайте застраховка гражданска отговорност онлайн. Безплатна доставка на стикер и зелена карта."
      ),
      keywords: t(
        "thirdPartyLiabilityInsuranceKeywords",
        "гражданска отговорност, гражданска отговорност цена, застраховка гражданска отговорност, гражданска отговорност калкулатор, онлайн калкулатор гражданска отговорност, Гражданска отговорност онлайн, гражданска отговорност цени, гражданска отговорност най-ниски цени, гражданска отговорност на автомобилистите, ГО, онлайн ГО"
      ),
      canonical: "grazhdanska-otgovornost-online/",
      image: "product/product_card_go.jpg"
    },
    /*   {
      pageName: "/grazhdanska-otgovornost-vnoski/",
      title: "Гражданска отговорност - плащане на вноски",
      description: "Платете онлайн вашата предстояща вноска по застраховка Гражданска отговорност. Бързо и лесно, безплатна доставка на стикер и зелена карта.",
      keywords:
        "Гражданска отговорност онлайн вноски, Застраховка гражданска отговорност вноски, гражданска отговорност на автомобилистите вноски, ГО вноска, онлайн ГО вноски, вноска по гражданска отговорност",
      canonical: "grazhdanska-otgovornost-vnoski/",
      image: "product/product_card_go.jpg"
    }, */
    {
      pageName: "/grazhdanska-otgovornost/",
      title: t("thirdPartyLiabilityInsuranceTitle2", "Гражданска отговорност. Сравни цените и спести до 50%."),
      description: t("thirdPartyLiabilityInsuranceDescription2", "Гражданска отговорност. Сравни цените и спести до 50%."),
      keywords: t("thirdPartyLiabilityInsuranceKeywords2", "Гражданска отговорност"),
      canonical: "grazhdanska-otgovornost/",
      image: "product/product_card_go.jpg"
    },
    {
      pageName: "/about-us/",
      title: t("AboutUs", "За Нас"),
      description: t("boleronTeamKeyword", "Екип Болерон"),
      keywords: t("boleronTeamKeyword", "Екип Болерон"),
      canonical: "about-us/",
      image: "product/product_card_main.jpg"
    },
    {
      pageName: "/avtomobilni-zastrahovki/",
      title: t("key14049", "Автомобилни застраховки: Онлайн сключване за минути"),
      description: t(
        "carInsuranceDescription",
        "Открийте бързо и лесно най-добрите автомобилни застраховки с Boleron. Изберете подходящото покритие, сключете онлайн за минути и се възползвайте от експертни съвети и конкурентни цени."
      ),
      keywords: "",
      canonical: "avtomobilni-zastrahovki/",
      image: "product/product_card_main.jpg"
    },
    {
      pageName: "/best-doctor/",
      keywords: "бест докторс, best doctors",
      canonical: "best-doctors/",
      title: t("healthInsuranceBestDoctorsTitle", "Здравна Застраховка (Best Doctors): Купи Онлайн | Boleron"),
      description: t(
        "healthInsuranceCriticalIllnessDescription",
        "Здравна застраховка за тежки заболявания 'Здраве с предимство' (бивша Best Doctors). Гарантирай си медицинска помощ при злополука и спокойствие с нашето здравно застраховане."
      ),
      image: "product/product_card_hwp.jpg",
      schemaMarkup: schemaHWP
    },
    {
      pageName: "/contacts/",
      title: t("key14059", "Болерон Контакти"),
      description: t("boleronOnlineInsuranceDescription", "Болерон - Онлайн застраховки. Всички застраховки Онлайн. Купи бързо и лесно и получи полицата веднага по имейл."),
      keywords: t("boleronContactsKeywords", "Болерон контакти, Boleron контакти"),
      canonical: "contacts/",
      image: "product/product_card_main.jpg",
      schemaMarkup: schemaContacts
    },
    {
      pageName: "/detska-zastrahovka/",
      titlet: t("childInsuranceTitle", "Детска застраховка: Купи онлайн | Boleron"),
      description: t(
        "childInsuranceDescription",
        "Осигуряване на финансова защита на детето и бъдеще с комбинация от спестовни и застрахователни компоненти с детска застраховка. Купи онлайн!"
      ),
      keywords: t("key14065", "Детска застраховка, здравна детска застраховка"),
      canonical: "detska-zastrahovka/",
      image: "product/product_card_main.jpg",
      schemaMarkup: schemaKid
    },
    {
      pageName: "/dopulnitelno-zdravno-bulstrad/",
      keywords: t("key14090", "здравна застраховка, здравно осигуряване фирма, допълнително здравно, здравно осигуряване малък бизнес, профилактика"),
      canonical: "dopulnitelno-zdravno-bulstrad/",
      title: t("key14091", "Допълнително здравно осигуряване към Булстрад Живот"),
      description: t(
        "additionalHealthInsuranceDescription",
        "Допълнителното здравно осигуряване на Булстрад Живот е подходящо за фирми между 5 и 40 човека, като може да се включват в покритието и членове на семейства на служителите."
      ),
      image: "product/product_card_main.jpg",
      schemaMarkup: schemaLFE
    },
    {
      pageName: "/firmena-zastrahovka-zhivot/",
      title: t("Company insurance Life", "Фирмена Застраховка Живот"),
      description: t(
        "corporateLifeInsuranceDescription",
        "Фирмена Застраховка Живот е цялостно решение за защита на служителите и техните семейства, което демонстрира ангажираност към благосъстоянието на екипа. Научете как този вид групова застраховка може да предложи финансова сигурност в случай на неочаквани събития и да повиши мотивацията и лоялността на служителите. Осигурете стабилност и доверие във вашата компания."
      ),
      keywords: t("Company insurance Life", "Фирмена Застраховка Живот"),
      canonical: "firmena-zastrahovka-zhivot/",
      image: "product/product_card_main.jpg",
      schemaMarkup: schemaCI
    },
    {
      pageName: "/firmeni-zastrahovki/",
      title: t("key14121", "Фирмени застраховки: Защита за вашия бизнес"),
      description: t(
        "businessInsuranceDescription",
        "Осигурете сигурност за вашия бизнес с фирмените застраховки на Boleron. Получете персонализирани решения, съобразени с вашите нужди, и сключете онлайн застраховка бързо и лесно."
      ),
      keywords: "",
      canonical: "firmeni-zastrahovki/",
      image: "product/product_card_main.jpg"
    },
    {
      pageName: "/proverka-elektronna-vinetka/",
      title: t("eVignetteCheckTitle", "Проверка за електронна винетка онлайн (тол плащане) | Болерон"),
      description: t(
        "eVignetteCheckDescription",
        "Проверете лесно и бързо наличието на винетка за вашето превозно средство в България. С Boleron можете и да закупите електронна винетка онлайн само с няколко клика."
      ),
      keywords: "",
      canonical: "proverka-elektronna-vinetka/",
      schemaMarkup: schemaVigCheck,
      image: "product/product_card_glo.jpg"
    },
    {
      pageName: "/proverka-globi-kat-grazhdanska/",
      title: t("trafficFinesCheckTitle", "Онлайн проверка за глоби от КАТ, фишове и нарушения | Boleron"),
      description: t(
        "key14506",
        "Ще да те информираме за глоби от КАТ, за изтичаща Застраховка, Винетка и ГТП. Безплатно можеш да направиш онлайн проверка на всичко за твоя автомобил."
      ),
      keywords: t(
        "key14507",
        "Проверка за глоби, проверка за глоби от КАТ, проверка за валидност на гражданска отговорност, проверка за валидност на винетка, проверка ГТП, глоби,проверка глоби кат по егн,проверка на глоби, проверка на фиш, проверка на глоби за превишена скорост, как да проверя дали имам глоби, нарушения кат, проверка на електронен фиш"
      ),
      canonical: "proverka-globi-kat-grazhdanska/",
      image: "product/product_card_glo.jpg",
      schemaMarkup: schemaGLO
    },
    {
      pageName: "/proverka-grazhdanska/",
      title: t("thirdPartyInsuranceCheckTitle", "Проверка на Гражданска отговорност онлайн | Boleron"),
      description: t(
        "thirdPartyInsuranceCheckDescription",
        "Check the validity of your vehicle's third-party liability insurance quickly and easily with Boleron. Enter the registration number and ticket number - convenient and secure!"
      ),
      keywords: "",
      canonical: "proverka-grazhdanska/",
      schemaMarkup: schemaVigCheck,
      image: "product/product_card_glo.jpg"
    },
    {
      pageName: "/proverka-tehnicheski-pregled/",
      title: t("vehicleInspectionCheckTitle", "Проверка на технически преглед МПС онлайн | Boleron"),
      description: t(
        "vehicleInspectionCheckDescription",
        "Проверете валидността на техническия преглед на вашето МПС онлайн с Boleron. Въведете регистрационен номер и номер на малък талон, за да получите бърза и точна информация."
      ),
      keywords: "",
      canonical: "proverka-tehnicheski-pregled/",
      schemaMarkup: schemaGTPCheck,
      image: "product/product_card_glo.jpg"
    },
    {
      pageName: "/vehicle-history/",
      title: t("damageCheckTitle", "Проверка на щети - провери историята на автомобил | Boleron"),
      description: t(
        "damageCheckDescription",
        "С онлайн услугата проверка на щети получаваш информация за застрахователни щети в Европа, произшествия, произход, пробег и дали автомобилът е “тотална щета.”"
      ),
      keywords: t("damageHistoryVinNumber", "Damage history, VIN number"),
      canonical: "vehicle-history/",
      image: "product/product_card_his.jpg",
      schemaMarkup: schemaHIS
    },
    {
      pageName: "/zastrahovka-bolnichen-prestoi/",
      title: t("hospitalStayHealthInsurance", "Здравна застраховка за болничен престой - Уника | Boleron"),
      description: t("hospitalStayInsurance", "Застраховка за болничен престой. Застраховка за болничен престой - Здраве и Спокойствие от Уника."),
      keywords: t("key14605", "Здравна застраховка за болничен престой"),
      canonical: "zastrahovka-bolnichen-prestoi/",
      image: "product/product_card_med.jpg",
      schemaMarkup: schemaBol
    },
    {
      pageName: "/zastrahovka-jivot/",
      title: t("termLifeInsurance", "Срочна Застраховка Живот: Купи Онлайн | Boleron"),
      description: t("key14630", "Срочната застраховка Живот осигурява финансова защита за близките ти при непредвидени събития. Получи персонализирани оферти и купи онлайн."),
      keywords: t("key14631", "Застраховка Живот, защита на семейството"),
      canonical: "zastrahovka-jivot/",
      image: "product/product_card_lfe.jpg",
      schemaMarkup: schemaLFE
    },
    {
      pageName: "/zastrahovki-zhivot/",
      title: t("lifeInsuranceTitle", "Животозастраховане: Защита за вас и вашето семейство | Boleron"),
      description: t(
        "insuranceOfferDescription",
        "Изберете най-добрата животозастраховка и здравни застраховки с Boleron. Лесно сключване онлайн и индивидуални решения за спокойствие и сигурност на бъдещето."
      ),
      keywords: t("key14653", "застраховки живот, видове застраховки живот"),
      canonical: "zastrahovki-zhivot/",
      schemaMarkup: schemaLfeCategory,
      image: "product/product_card_lfe.jpg"
    },
    {
      pageName: "/zdravna-zastrahovka-health-club/",
      keywords: t("healthInsuranceKeywords", "здравна застраховка, семейна здравна застраховка, Health Club, международна здравна застраховка"),
      canonical: "zdravna-zastrahovka-health-club/",
      title: t("healthClubSeoTitle", "Health Club: международна здравна застраховка"),
      description: t(
        "healthClubDescription",
        "Присъединете се към Health Club и се възползвайте от ексклузивна международна здравна застраховка, съобразена с вашия начин на живот. Открийте персонализирани застрахователни планове, които ви осигуряват спокойствие и защита във всяка ситуация."
      ),
      image: "product/product_card_hcl.jpg",
      schemaMarkup: schemaLFE
    },
    {
      pageName: "/zdravni-zastrahovki/",
      title: t("key14669", "Здравни застраховки: Защита за вашето здраве"),
      description: t(
        "healthProtectionDescription",
        "Осигурете си най-добрата здравна защита с Boleron. Изберете персонализирани здравни застраховки за вас и вашето семейство с бързо и лесно онлайн сключване на полицата."
      ),
      keywords: t("key14670", "здравни застраховки, допълнително здравно"),
      canonical: "zdravni-zastrahovki/",
      image: "product/product_card_main.jpg"
    },
    {
      pageName: "/fine-check-result/",
      title: t("key14105", "Резултат от проверка за глоби от КАТ и валидност на гражданска отговорност и винетка"),
      canonical: "fine-check-result/"
    },
    {
      pageName: "/kasko-kalkulator/",
      title: t("cascoCalculatorTitle", "Калкулатор Каско [спести до 30%] - Купи Автокаско Онлайн | Boleron"),
      description: t("cascoCalculatorDescription", "Каско калкулатор - сравни цените и спести до 30%. Купи автокаско онлайн и получи полицата веднага по имейл. Бързо и лесно."),
      keywords: t(
        "cascoCalculatorKeywords",
        "каско калкулатор, автокаско калкулатор, каско, автокаско, калкулатор каско, булстрад каско, каско цена, автокаско цени, kasko, застраховка каско, левинс каско, застраховка каско калкулатор, пълно автокаско цена, kasko kalkulator, автокаско онлайн, автокаско подновяване, каско подновяване, каско булстрад, каско евроинс, каско алианц, каско дзи, каско армеец, каско левинс, онлайн каско"
      ),
      canonical: "kasko-kalkulator/",
      image: "/product/product_card_csc.jpg"
    },
    {
      pageName: "/kasko-plus-grazhdanska/",
      title: t("cascoAndThirdPartyLiabilityCalculatorTitle", "Каско и Гражданска Калкулатор | Boleron"),
      description: t(
        "cascoAndThirdPartyLiabilityCalculatorDescription",
        "Онлайн калкулатор за каско и застраховка гражданска отговорност. Спести до 50% с комбиниран продукт. Сравни цените и купи онлайн бързо и лесно."
      ),
      keywords: t(
        "cascoAndThirdPartyLiabilityCalculatorKeywords",
        "каско + гражданска, каско и гражданска, гражданска отговорност и каско, автокаско и гражданска, гражданска отговорност и каско калкулатор"
      ),
      canonical: "kasko-plus-grazhdanska/",
      image: "product/product_card_go_plus_csc"
    },
    {
      pageName: "/planinska-zastrahovka/",
      title: t("mountainInsuranceOnlineTitle", "Планинска застраховка онлайн | Boleron"),
      description: t(
        "mountainInsuranceOnlineDescription",
        "Планинска застраховка онлайн. Получавате полица с медицинска застраховка за пътувания в планината веднага по имейл. Бързо и лесно от Boleron."
      ),
      keywords: t(
        "mountainInsuranceOnlineKeywords",
        "планинска застраховка, планинска застраховка онлайн, планинска застраховка цена, ски застраховка, застраховка за планина, ПСС застраховка, уника планинска застраховка, застраховка за скиори, къде да си направя планинска застраховка, онлайн планинска застраховка"
      ),
      canonical: "planinska-zastrahovka/",
      image: "product/product_card_mtn.jpg"
    },
    {
      pageName: "/zastrahovka-ekstremni-sportove/",
      title: t("extremeSportsInsuranceTitle", "Застраховка екстремни спортове: Купи онлайн | Boleron"),
      description: t(
        "extremeSportsInsuranceDescription",
        "Застраховка екстремни спортове - най-добрата защита за практикуващи екстремен спорт в планината. Купи онлайн и получи полица веднага по имейл."
      ),
      keywords: t("extremeSportsInsuranceKeywords", "Застраховка екстремни спортове, Застраховка екстремни спортове уника, екстремни спортове, застраховка извън пистите"),
      canonical: "zastrahovka-ekstremni-sportove/",
      image: "product/product_card_ext.jpg"
    },
    {
      pageName: "/zastrahovka-imushtestvo/",
      title: t("propertyInsuranceTitle", "Застраховка Имущество: Купи Онлайн | Boleron"),
      description: t(
        "propertyInsuranceDescription",
        "Застраховка недвижимо имущество, апартамент или къща без необходимост от оглед. Максимална защита. Получаваш полицата веднага по имейл. Купи онлайн!"
      ),
      keywords: t(
        "propertyInsuranceKeywords",
        "застраховка имущество, застраховка на жилище, застраховка за дома, застраховка имот, застраховка на апартамент, застраховка на къща, калкулатор застраховка имущество"
      ),
      canonical: "zastrahovka-imushtestvo/",
      image: "product/product_card_prp.jpg"
    },
    {
      pageName: "/zastrahovka-patuvane-v-chuzhbina/",
      title: t("travelInsuranceAbroadTitle", "Застраховка Пътуване в Чужбина: Купи Онлайн | Boleron"),
      description: t(
        "travelInsuranceAbroadDescription",
        "Онлайн застраховка пътуване в чужбина. Медицинска застраховка за чужбина с покритие за КОВИД 19. Помощ при пътуване в чужбина. Получаваш полицата веднага по имейл."
      ),
      keywords: t(
        "travelInsuranceAbroadKeywords",
        "Застраховка пътуване в чужбина, медицинска застраховка за чужбина, застраховка за пътуване в чужбина, застраховка при пътуване, застраховка пътуване, ковид застраховка, Онлайн застраховка Пътуване в чужбина, медицинска застраховка за чужбина онлайн"
      ),
      canonical: "zastrahovka-patuvane-v-chuzhbina/",
      image: "product/product_card_tra.jpg"
    },
    {
      pageName: "/login-without-pass/",
      title: t("myProfileLoginTitle", "Моят профил - Вход"),
      description: t("myProfileLoginDescription", "Достъп до Вашия профил в Boleron"),
      keywords: "",
      canonical: "login-without-pass/"
    },
    {
      pageName: "/documents/",
      title: t("documentsTitle", "Документи"),
      description: t("documentsDescription", "Списък с документи и сертификати към онлайн платформа за застраховане - Boleron"),
      keywords: "",
      canonical: "documents/"
    }
  ]
};

export default {
  autoProducts,
  mainProducts,
  companyProducts,
  lifeProducts,
  onlineProducts,
  healthProducts,
  productImages,
  header,
  footer,
  marketingText,
  messenger: false,
  tenantSettings,
  isUnregisteredVehicleEnabled: true,
  isUnregisteredVehicleCascoPlusGOEnabled: false, // todo temp variable, will be removed in future
  isCustomCarPriceEnabled: true,
  referral: {
    referralDiscount: "15%",
    referredDiscount: "10%"
  }
};
