import React from "react";
import Logo from "../../../static/png/vivacom_logo_negative.png";
import LogoSvg from "../../../static/images/vivacom_white.svg";
import ProductsService from "../../services/ProductsService";
import CSCPlusGOIcon from "../../images/vivacom/landing/csc-go.svg";
import GOIcon from "../../images/vivacom/landing/go.svg";
import CSCIcon from "../../images/vivacom/landing/csc.svg";
import TRAIcon from "../../images/vivacom/landing/tra.svg";
import PRPIcon from "../../images/vivacom/landing/prp.svg";
// import PRPIconDiscount from "../../images/vivacom/landing/prp_icon_discount.svg";
// import TRAIconDiscount from "../../images/vivacom/landing/tra_icon_discount.svg";
// import CSCIconDiscount from "../../images/vivacom/landing/csc_icon_discount.svg";
import MTNIcon from "../../images/vivacom/landing/mtn.svg";
import EXTIcon from "../../images/vivacom/landing/ext.svg";
import HLTIcon from "../../images/vivacom/landing/hlt.svg";

const mainProducts = [
  {
    id: "GO",
    title: language => ProductsService.getProductName("GO", language),
    price: "Compare prices and save",
    link: "/grazhdanska-otgovornost-online/",
    checklist: ["Избор от 7 застрахователя", "See prices in just 30 seconds", "Free Shipping"],
    successfulPurchaseMessage: "",
    couriers: ["econt"],
    insurers: [
      {
        id: "bulstrad",
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/GO_HELP.pdf"
          },
          {
            id: "euDocumentUrlGO_BULSTRAD",
            label: "ЗЕАД Булстрад Виена Иншурънс Груп",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_BULSTRAD_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_UNIQA",
            label: "ЗК УНИКА АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_UNIQA_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_EUROINS",
            label: "ЗД Евроинс АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_EUROINS_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_DALBOGG",
            label: "ЗАД Далл Богг: Живот и Здраве АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_DALBOGG_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_DZI",
            label: "ДЗИ-Общо застраховане ЕАД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_DZI_A3.pdf"
          },
          {
            id: "euDocumentUrlCSC_OU_ALLIANZ",
            label: "ЗАД Алианц България",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_OU_ALLIANZ.pdf"
          },
          {
            id: "euDocumentUrlGO_LEVINS",
            name: "ЗК Лев Инс АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_LEVINS_A3.pdf"
          }
        ]
      },
      {
        id: "dzi",
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/GO_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_DZI_A3.pdf"
          }
        ]
      },
      {
        id: "euroins",
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/GO_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_EUROINS_A3.pdf"
          }
        ]
      },
      {
        id: "uniqa",
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/GO_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/GO_UNIQA_A3.pdf"
          }
        ]
      },
      {
        id: "allianz",
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/GO_HELP.pdf"
          }
        ]
      },
      {
        id: "levins",
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/GO_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/GO_LEVINS_A3.pdf"
          }
        ]
      },
      {
        id: "dallbogg",
        paymentModes: ["1"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/GO_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_DALLBOGG_A3.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "CSC",
    title: language => ProductsService.getProductName("CSC", language),
    price: "Prices from BGN 249",
    link: "/kasko-kalkulator/",
    checklist: ["Онлайн калкулатор", "Automatic market valuation", "You get the policy right away"],
    successfulPurchaseMessage: "",
    couriers: ["econt"],
    offlineOffer: false,
    insurers: [
      {
        id: "bulstrad",
        hasPrice: true,
        hasPromoCode: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/CSC_BULSTRAD_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_OU.PDF"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/BULSTRAD_PDOG.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPrice: true,
        hasPromoCode: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/CSC_EUROINS_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_EUROINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_EUROINS_OU.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_EUROINS.pdf"
          }
        ]
      },
      {
        id: "levins",
        hasPrice: true,
        hasPromoCode: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/CSC_LEVINS_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_OU.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "TRA",
    title: language => ProductsService.getProductName("TRA", language),
    price: "Prices from BGN 2.00 per day",
    link: "/zastrahovka-patuvane-v-chuzhbina/",
    checklist: ["Covers treatment up to €50,000", "You get the policy right away", "24/7 worldwide support"],
    successfulPurchaseMessage: "",
    couriers: ["econt"],
    insurers: [
      {
        id: "bulstrad",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_BULSTRAD.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_BULSTRAD.pdf"
          },

          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/TRA_HELP_BULSTRAD.pdf"
          }
        ]
      },
      {
        id: "levins",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_LEVINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_LEVINS.pdf"
          },
          { id: "predogUrl", label: "Преддоговорна информация", url: "#" },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/TRA_HELP_LEVINS.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_EUROINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_EUROINS.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_EUROINS.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/TRA_HELP_EUROINS.pdf"
          }
        ]
      },
      {
        id: "uniqa",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_UNIQA.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_UNIQA.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_PREDOG.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/TRA_HELP_UNIQA.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "PRP",
    title: language => ProductsService.getProductName("PRP", language),
    price: "Prices from BGN 9.99",
    link: "/zastrahovka-imushtestvo/",
    checklist: ["Fire, flood, earthquake", "Automatic monthly payment", "Completely online, paperless"],
    successfulPurchaseMessage: "",
    couriers: ["econt"],
    insurers: [
      {
        id: "uniqa",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_A3_20191408.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_OU_20191408.pdf"
          },
          {
            id: "additionalTermsUrl",
            label: "Специални условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_SU_20191408.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_PREDOG.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/PRP_HELP_UNIQA.pdf"
          },
          {
            id: "assistanceTermsUrl",
            label: 'Клауза "Домашен асистанс"',
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_UNIQA_HOME_ASSISTANT.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          },
          {
            id: "goTermsUrl",
            label: 'ОУ "Обща Гражданска Отговорност"',
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_UNIQA_OU_OGO.pdf"
          } // само ако е избран чека в допълнителни покрития
        ]
      },
      {
        id: "bulstrad",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_BULSTRAD_BONUS_DOM+_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_BULSTRAD_BONUS_DOM+_OU.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/PRP_HELP_BULSTRAD.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "MTN",
    title: language => ProductsService.getProductName("MTN", language),
    price: "Prices from BGN 4.08",
    link: "/planinska-zastrahovka/",
    checklist: ["Rescue and medical expenses", "Completely online, paperless", "You get the policy right away"],
    successfulPurchaseMessage: "",
    couriers: ["econt"],
    insurers: [
      {
        id: "euroins",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_EUROINS_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_EUROINS_OU.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/MTN_HELP_EUROINS.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "EXT",
    title: language => ProductsService.getProductName("EXT", language),
    price: "Prices from BGN 19.99",
    link: "/zastrahovka-ekstremni-sportove/",
    checklist: ["Rescue and medical expenses", "Completely online, paperless", "You get the policy right away"],
    successfulPurchaseMessage: "",
    couriers: ["econt"],
    insurers: [
      {
        id: "euroins",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_EUROINS_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_EUROINS_OU.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/vivacom-documents/EXT_HELP_EUROINS.pdf"
          }
        ]
      }
    ]
  }
];
const onlineProducts = [];

const marketingText = "Съгласен ли си да получаваш от Vivacom оферти, намаления, промоции и подаръци?";

const header = {
  logo: <img style={{ width: 250, marginTop: 9 }} src={Logo} alt="logo" />,
  logoText: {
    disabled: true
  },
  links: [
    { id: "home", url: "/" },
    { id: "insurances", url: "" },
    { id: "services", disabled: true },
    { id: "aboutUs", url: "/about-us", disabled: true },
    { id: "contacts", url: "/contacts", disabled: true },
    { id: "myProfile", url: "/policies", disabled: false },
    { id: "blog", url: "/blog", disabled: true },
    { id: "bottomLogo", url: "/", disabled: false }
  ],
  downloadApp: {
    disabled: true
  },
  hr: {
    disabled: false
  }
};
const footer = {
  logo: <LogoSvg className="logo-footer" style={{ marginTop: -8 }} />,
  logoText: {
    disabled: true
  },
  sectionMenu: {
    disabled: true
  },
  sectionMessage: {
    disabled: true
  },
  sectionSocialMedia: {
    disabled: true
  },
  phone: {
    text: "0897 659 173",
    link: "+359897659173",
    isIconDissabled: true
  },
  email: {
    text: "support-insurance@vivacom.bg",
    isIconDissabled: true
  },
  hr: {
    disabled: false
  },

  footerText: {
    isCentered: false,
    paddingStyles: "pl-5 pr-2"
  }
};

const productImages = [
  {
    product: "CSC+GO",
    image: (
      <div className="is-flex is-align-items-center is-justify-content-center h-100 is-flex-direction-column is-primary-color">
        <CSCPlusGOIcon className="vivacom-index-icon" />
        <div className="product-hr-vivacom" />
      </div>
    )
  },
  {
    product: "GO",
    image: (
      <div className="boleron-index-icon-wrapper">
        <GOIcon className="vivacom-index-icon" />
        <div className="product-hr-vivacom" />
      </div>
    )
  },
  {
    product: "CSC",
    image: (
      <div className="is-flex is-align-items-center is-justify-content-center h-100 is-flex-direction-column is-primary-color">
        <CSCIcon className="vivacom-index-icon" />
        <div className="product-hr-vivacom" />
      </div>
    )
  },
  {
    product: "TRA",
    image: (
      <div className="is-flex is-align-items-center is-justify-content-center h-100 is-flex-direction-column is-primary-color">
        <TRAIcon className="vivacom-index-icon" />
        <div className="product-hr-vivacom" />
      </div>
    )
  },
  {
    product: "PRP",
    image: (
      <div className="boleron-index-icon-wrapper">
        <PRPIcon className="vivacom-index-icon" />
        <div className="product-hr-vivacom" />
      </div>
    )
  },
  {
    product: "MTN",
    image: (
      <div className="is-flex is-align-items-center is-justify-content-center h-100 is-flex-direction-column is-primary-color">
        <MTNIcon className="vivacom-index-icon" />
        <div className="product-hr-vivacom" />
      </div>
    )
  },
  {
    product: "EXT",
    image: (
      <div className="is-flex is-align-items-center is-justify-content-center h-100 is-flex-direction-column is-primary-color">
        <EXTIcon className="vivacom-index-icon" />
        <div className="product-hr-vivacom" />
      </div>
    )
  },
  {
    product: "HLT",
    image: (
      <div className="is-flex is-align-items-center is-justify-content-center h-100 is-flex-direction-column is-primary-color">
        <HLTIcon className="vivacom-index-icon" />
        <div className=" product-hr-vivacom" />
      </div>
    )
  }
];

const tenantSettings = {
  defaultSEO: {
    title: "Vivacom",
    description: "Виваком застраховане",
    lang: "bg"
  },
  showMetaDataAndSchemas: false,
  companyName: "Vivacom",
  websiteName: "Vivacom Insurance",
  companyNameFull: '"Vivacom Онлайн застраховки"',
  companyURL: "https://insurance.vivacom.bg/",
  isAppleGooglePayActive: false,
  couriers: ["econt"],
  pagesSEO: () => [
    {
      pageName: "/",
      title: "Виваком онлайн застраховки - Гражданска отговорност",
      description: "Погрижи се за бъдещето с лесна и сигурна онлайн застраховка от Vivacom. Получаваш полицата веднага по имейл и уведомления за изтичаща застраховка.",
      keywords: "онлайн застраховки, виваком застрахователен брокер, виваком застраховки, vivacom online insurance",
      canonical: "/",
      image: "product/product_card_viva_all.jpg"
    },
    {
      pageName: "/grazhdanska-otgovornost-online/",
      title: "Гражданска отговорност - поръчай и плати онлайн",
      description: "Застраховка Гражданска отговорност: Защитава ви от правни и финансови последици, когато вашите действия причиняват вреда на други. За отговорен живот.",
      keywords: "Vivacom Online Insurance, Виваком онлайн застраховки,онлайн гражданска, сравнение цени гражданска",
      canonical: "grazhdanska-otgovornost-online/",
      image: "product/product_card_viva_go.jpg"
    },
    {
      pageName: "/grazhdanska-otgovornost/",
      title: "Данни за МПС",
      description: "Данни за МПС",
      keywords: "",
      canonical: "grazhdanska-otgovornost/",
      image: "product/product_card_viva_go.jpg"
    },
    {
      pageName: "/kasko-kalkulator/",
      title: "Автокаско калкулатор - сравняваш оферти бързо и сигурно",
      description: "Комплексна автомобилна застраховка, покриваща щети от катастрофи, кражби и природни бедствия, осигуряваща пълна защита на вашия автомобил само с Vivacom.",
      keywords: "Vivacom Online Insurance, Виваком онлайн застраховки,онлайн автокаско, електронна полица автокаско, сравни цените автокаско",
      canonical: "kasko-kalkulator/",
      image: "product/product_card_viva_csc.jpg"
    },
    {
      pageName: "/planinska-zastrahovka/",
      title: "Планинска застраховка - разходи за спасяване",
      description:
        "Повишете защитата си с покритие за приключения на открито, ски и планинарство. Защита срещу рискове при дейности на голяма надморска височина с Виваком онлайн застраховки.",
      keywords: "Vivacom Online Insurance, Виваком онлайн застраховки,застраховка планинска, разходка в планината, разходи за спасяване, ПСС",
      canonical: "planinska-zastrahovka/",
      image: "product/product_card_viva_mtn.jpg"
    },
    {
      pageName: "/zastrahovka-ekstremni-sportove/",
      title: "Застраховка екстремни спортове",
      description: "Изживейте тръпката в безопасност с Vivacom. Покритие за любителско практикуване на дейности като скално катерене, ски и сноуборд, планиско колоездене и др.",
      keywords: "Vivacom Online Insurance, Виваком онлайн застраховки,застраховка екстремни спортове, планинска застраховка с покритие екстремен спорт",
      canonical: "zastrahovka-ekstremni-sportove/",
      image: "product/product_card_viva_ext.jpg"
    },
    {
      pageName: "/zastrahovka-imushtestvo/",
      title: "Застраховки на движимо и недвижимо имущество",
      description: "Застраховка Имущество, гарантира финансови средства за възстановяване на дома след бедствия. Защитете дома си онлайн с Vivacom.",
      keywords: "Vivacom Online Insurance, Виваком онлайн застраховки,застраховка на имущество, застраховка на къща, застраховка на апартамент",
      canonical: "zastrahovka-imushtestvo/",
      image: "product/product_card_viva_prp.jpg"
    },
    {
      pageName: "/zastrahovka-patuvane-v-chuzhbina/",
      title: "Mедицинска застраховка за пътуване чужбина",
      description: "Защитете здравето си, докато сте на почивка или бизнес пътуване в чужбина с покритие за медицинска помощ, включително и КОВИД 19 - Vivacom онлайн застраховки.",
      keywords: "Vivacom Online Insurance, Виваком онлайн застраховки,застраховка за пътуване в чужбина,застраховка при пътуване,застраховка пътуване",
      canonical: "zastrahovka-patuvane-v-chuzhbina/",
      image: "product/product_card_viva_tra.jpg"
    },
    {
      pageName: "/login-without-pass/",
      title: "Моят профил - Вход",
      description: "Достъп до Вашия профил във Vivacom Online Insurance",
      keywords: "",
      canonical: "login-without-pass/"
    },
    {
      pageName: "/documents/",
      title: "Документи",
      description: "Списък с документи и сертификати към онлайн платформа за застраховане - Vivacom Online Insurance",
      keywords: "",
      canonical: "documents/"
    }
  ]
};

export default {
  mainProducts,
  onlineProducts,
  productImages,
  header,
  footer,
  marketingText,
  messenger: false,
  tenantSettings,
  isCustomCarPriceEnabled: false,
  isUnregisteredVehicleEnabled: false
};
