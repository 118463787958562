import React, { useEffect, useState } from "react";

import Check from "@icons/checkmark.svg";
import Notifications from "../common/Notifications";
import { actionTypes } from "../../config/PaymentActionTypes";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";

import "@styles/components/loader-boleron.sass";

// Config For steps seen in design - > can be refactored to and object and with a fnc to dynamically
const stepsConfig = {
  case1: [
    {
      stepNumber: 1,
      pendingTitle: <Trans i18nKey="key241451"> Обработка на плащане</Trans>,
      resolvedTitle: <Trans i18nKey="key24155">Плащането е успешно</Trans>
    },
    {
      stepNumber: 2,
      pendingTitle: <Trans i18nKey="key241461">Издаване на полица</Trans>,
      resolvedTitle: <Trans i18nKey="key24156">Полицата е издадена!</Trans>
    },
    {
      stepNumber: 3,
      pendingTitle: <Trans i18nKey="key24147">Подписване на полица</Trans>,
      resolvedTitle: <Trans i18nKey="key241471">Полицата е подписана!</Trans>
    }
  ],
  case2: [
    {
      stepNumber: 1,
      pendingTitle: <Trans i18nKey="key24148">Обработка на заявка</Trans>,
      resolvedTitle: <Trans i18nKey="key241482">Заявката е приета!</Trans>
    }
  ],
  case3: [
    {
      stepNumber: 1,
      pendingTitle: <Trans i18nKey="key241451"> Обработка на плащане</Trans>,
      resolvedTitle: <Trans i18nKey="key24155">Плащането е успешно</Trans>
    },
    {
      stepNumber: 2,
      pendingTitle: <Trans i18nKey="key24148">Обработка на заявка</Trans>,
      resolvedTitle: <Trans i18nKey="key241482">Заявката е приета!</Trans>
    }
  ]
};

const getSteps = ({ type, paymentStatus, policyStatus, policySignedStatus, policyInitialStatus, customSteps, customTitle, t }) => {
  let currentSteps = [];
  let currentTitle = "";

  // Note: if payment status is failed we still show loading indicator to avoid pre-rendering before redirecting to error message screen

  if (actionTypes.CUSTOM === type) {
    currentSteps = customSteps;
    currentSteps[0].isPending = true;
    currentTitle = customTitle;
  } else if (actionTypes.POLICY_OFFER === type || actionTypes.POLICY_REQUEST_CASH_ON_DELIVERY === type || actionTypes.PAY_INSTALLMENT_CASH_ON_DELIVERY === type) {
    currentSteps = stepsConfig.case2;
    currentSteps[0].isPending = true;

    currentTitle = t("key24148", "Обработка на заявка");
  } else if (policyInitialStatus === true) {
    currentSteps = stepsConfig.case3;
    currentTitle = t("key24148", "Обработка на заявка");
    currentSteps[0].isPending = paymentStatus === "pending" || paymentStatus === "failed";
    currentSteps[0].isResolved = paymentStatus === "completed";
    currentSteps[1].isPending = paymentStatus === "completed" && policyStatus === "pending";
    currentSteps[1].isResolved = policyStatus === "completed";
    currentSteps[1].isUpcomming = paymentStatus === "pending" || paymentStatus === "failed";
  } else if ([actionTypes.PAY_INSTALLMENT_CARD, actionTypes.PAY_INSTALLMENT_EXISTING_CARD].includes(type)) {
    currentSteps = stepsConfig.case3;
    currentTitle = t("key24148", "Обработка на заявка");
    currentSteps[0].isPending = paymentStatus === "pending" || paymentStatus === "failed";
    currentSteps[0].isResolved = paymentStatus === "completed";
    currentSteps[1].isPending = paymentStatus === "completed" && policyStatus === "pending";
    currentSteps[1].isResolved = policyStatus === "completed";
    currentSteps[1].isUpcomming = paymentStatus === "pending" || paymentStatus === "failed";
  } else {
    currentSteps = stepsConfig.case1;
    currentTitle = t("key241461", "Издаване на полица");
    currentSteps[0].isPending = paymentStatus === "pending" || paymentStatus === "failed";
    currentSteps[0].isResolved = paymentStatus === "completed";
    currentSteps[1].isPending = paymentStatus === "completed" && (policyStatus === "pending" || policyStatus === "failed");
    currentSteps[1].isResolved = policyStatus === "completed";
    currentSteps[1].isUpcomming = paymentStatus === "pending" || paymentStatus === "failed";
    currentSteps[2].isPending = policyStatus === "completed" && (policySignedStatus === "pending" || policySignedStatus === "failed");
    currentSteps[2].isResolved = policySignedStatus === "completed";
    currentSteps[2].isUpcomming = paymentStatus === "pending" || policyStatus === "pending" || policyStatus === "failed" || paymentStatus === "failed";
  }

  return { currentTitle, currentSteps };
};

const PercentageBoleronLoader = props => {
  const { isJustLoader, cssDuration, t } = props;

  if (isJustLoader) {
    const [percentage, setPercentage] = useState(0);
    const match = cssDuration.match(/^sec(\d+)$/);
    const duration = match ? parseInt(match[1], 10) : 60;

    useEffect(() => {
      if (percentage >= 100) return;

      const increment = 100 / duration;
      const interval = setInterval(() => {
        setPercentage(prevPercentage => {
          const nextPercentage = Number((prevPercentage + increment).toFixed());
          return nextPercentage >= 100 ? 100 : nextPercentage;
        });
      }, 1000);

      return () => clearInterval(interval);
    }, [percentage, duration]);

    const loaderClass = percentage >= 100 ? "progress-full" : `progress-start ${cssDuration}`;

    return (
      <div className="progress-loader-container">
        <div className={`progress-loader ${loaderClass}`} />{" "}
        <span className="is-bold-family is-size-3-5 is-theme-text" style={{ width: 45, maxWidth: 45 }}>
          {percentage}%
        </span>
      </div>
    );
  }
  const currentStepConfig = getSteps(props);

  const getStepIndicatorClasses = item => {
    if (item.isPending) {
      return "active";
    }
    if (item.isResolved) {
      return "resolved";
    }
    return "";
  };

  const progressBarStylesGenerator = currentStepConfigProp => {
    const { currentSteps } = currentStepConfigProp;
    const stepsLength = currentSteps && currentSteps.length;
    const resolvedCount = currentSteps.filter(step => step.isResolved).length;
    const isComplete = stepsLength === resolvedCount;

    return isComplete ? "progress-full" : `progress-start ${props.cssDuration || "sec45"}`;
  };

  return (
    <div className="progress-wrapper">
      <h1 className="has-text-centered has-text-primary mt-2 boxTitle is-uppercase">{currentStepConfig.currentTitle}</h1>

      {/* Loader */}
      <div className="progress-loader-container">
        <div className={`progress-loader ${progressBarStylesGenerator(currentStepConfig)}`} />
      </div>
      {/* Loader */}

      <div className="progress-steps-container">
        {currentStepConfig.currentSteps.map(item => {
          const stepClasses = getStepIndicatorClasses(item);
          return (
            <div key={item.stepNumber} className={`progress-steps-outher ${stepClasses}`}>
              <div className={`progress-step-inner ${stepClasses}`}>
                {!item.isResolved && <p className="has-text-weight-bold">{item.stepNumber}</p>}
                {item.isResolved && <Check className="check-icon" />}
              </div>

              {item.isPending && (
                <div className="progress-step-text-container">
                  <p>{item.pendingTitle}</p>
                  <p className="progress-step-subtitle">{t("key24140", "В процес ...")}</p>
                </div>
              )}

              {item.isResolved && (
                <div className="progress-step-text-container is-primary">
                  <p>{item.resolvedTitle}</p>
                </div>
              )}
              {item.isUpcomming && (
                <div className="progress-step-text-container">
                  <p>{item.pendingTitle}</p>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {currentStepConfig.currentSteps.length === 3 && (
        <Notifications
          classes="notification-default"
          svgIcon="ExclamationMark"
          message={t(
            "signingPolicyDisclaimer",
            "<strong>Полицата се подписва </strong>от нас като застрахователен брокер и не е необходимо допълнително да се разпечатва и подписва."
          )}
          type="is-primary"
          subType="is-light"
          marginTop={20}
          marginBottom={40}
        />
      )}
    </div>
  );
};

export default PercentageBoleronLoader;
