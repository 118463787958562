import React, { lazy, Suspense, cloneElement } from "react";
import PropTypes from "prop-types";

import useClientServerContext from "../hooks/useClientServerContext";
import BoleronLoader from "../components/common/BoleronLoader";

const BubbledExclamation = lazy(() => import(`./component-svg/BubbledExclamation`));
const OnlineNoPaperIcon = lazy(() => import(`./component-svg/OnlineNoPaperIcon`));
const SingleCreditCard = lazy(() => import(`./component-svg/SingleCreditCard`));
const CardsBoleronIcon = lazy(() => import(`./component-svg/CardsBoleronIcon`));
const EmailBoleronIcon = lazy(() => import(`./component-svg/EmailBoleronIcon`));
const ExclamationMark = lazy(() => import(`./component-svg/ExclamationMark`));
const NoOfficeBoleron = lazy(() => import(`./component-svg/NoOfficeBoleron`));
const EmailContracts = lazy(() => import(`./component-svg/EmailContracts`));
const BoleronLoaderSVG = lazy(() => import(`./component-svg/BoleronLoader`));
const MagnifyingLens = lazy(() => import(`./component-svg/MagnifyingLens`));
const PolicyPayments = lazy(() => import(`./component-svg/PolicyPayments`));
const CarExclamation = lazy(() => import(`./component-svg/CarExclamation`));
const ErrorVivacom = lazy(() => import(`./component-svg/ErrorVivacom`));
const Chronometer = lazy(() => import(`./component-svg/Chronometer`));
const DeliveryBox = lazy(() => import(`./component-svg/DeliveryBox`));
const ShieldCheck = lazy(() => import(`./component-svg/ShieldCheck`));
const DoubleArrow = lazy(() => import(`./component-svg/DoubleArrow`));
const CheckCircle = lazy(() => import(`./component-svg/CheckCircle`));
const Delivery = lazy(() => import(`./component-svg/Delivery`));
const NoOffice = lazy(() => import(`./component-svg/NoOffice`));
const PdfSolid = lazy(() => import(`./component-svg/PdfSolid`));
const Download = lazy(() => import(`./component-svg/Download`));
const Location = lazy(() => import(`./component-svg/Location`));
const SadFace = lazy(() => import(`./component-svg/SadFace`));
const Success = lazy(() => import(`./component-svg/Success`));
const Card2 = lazy(() => import(`./component-svg/Card2`));
const Clock = lazy(() => import(`./component-svg/Clock`));
const User = lazy(() => import(`./component-svg/User`));
const X = lazy(() => import(`./component-svg/X`));

const allSvgs = {
  BubbledExclamation: <BubbledExclamation />,
  OnlineNoPaperIcon: <OnlineNoPaperIcon />,
  BoleronLoaderSVG: <BoleronLoaderSVG />,
  SingleCreditCard: <SingleCreditCard />,
  EmailBoleronIcon: <EmailBoleronIcon />,
  CardsBoleronIcon: <CardsBoleronIcon />,
  ExclamationMark: <ExclamationMark />,
  NoOfficeBoleron: <NoOfficeBoleron />,
  EmailContracts: <EmailContracts />,
  MagnifyingLens: <MagnifyingLens />,
  PolicyPayments: <PolicyPayments />,
  CarExclamation: <CarExclamation />,
  ErrorVivacom: <ErrorVivacom />,
  Chronometer: <Chronometer />,
  DeliveryBox: <DeliveryBox />,
  DoubleArrow: <DoubleArrow />,
  ShieldCheck: <ShieldCheck />,
  CheckCircle: <CheckCircle />,
  CreaditCardIcon: <Card2 />,
  PdfSolid: <PdfSolid />,
  Download: <Download />,
  NoOffice: <NoOffice />,
  Delivery: <Delivery />,
  Location: <Location />,
  SadFace: <SadFace />,
  Success: <Success />,
  Clock: <Clock />,
  User: <User />,
  X: <X />
};

const svgKeys = Object.keys(allSvgs);

const LazySVGS = ({ svg, fallback, directLoad, ...props }) => {
  PropTypes.checkPropTypes({ svg: PropTypes.oneOf(svgKeys).isRequired }, { svg }, "prop", "LazySVGS");

  const { isClient } = useClientServerContext();
  const setFallback = fallback || <BoleronLoader />;
  const isSvgNameCorrect = svgKeys.includes(svg);

  if (directLoad) {
    return cloneElement(allSvgs[svg], { ...props });
  }

  return isClient && isSvgNameCorrect ? <Suspense fallback={setFallback}>{cloneElement(allSvgs[svg], { ...props })}</Suspense> : setFallback;
};

export default LazySVGS;

LazySVGS.propTypes = {
  svg: PropTypes.string.isRequired
};
