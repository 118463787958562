/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";

import activeProducts from "../../config/activeProducts";

const SeoTenant = ({ ...props }) => {
  const { t } = useTranslation();
  const { pagesSEO, defaultSEO: defaults, websiteName, companyURL, showMetaDataAndSchemas } = activeProducts.tenantSettings;
  const { location, isNoIndex, isLoadingSeo } = props;
  const translatedPagesSEO = pagesSEO(t);
  const pageProps = translatedPagesSEO.find(p => {
    const strippedPathname = location?.pathname.replace(/^\/[a-z]{2}\//, "/");
    return p.pageName === strippedPathname;
  });

  // Temporary fix : "location.pathname" returns a path name with en/ whitch we don't have in pagesSEO hence pageProps is undefined
  // Probably a fix would be for vivacom for us to remove the en  paths from graphql
  // При добавяне на данни винаги трябва да се добавя във vivacom.js и boleron.js

  if (isLoadingSeo) {
    return (
      <Helmet>
        <link rel="canonical" href={`${companyURL}init-workflow`} />
        <meta property="og:url" content={`${companyURL}init-workflow`} />
        <meta data-react-helmet="true" name="robots" content="noindex, nofollow" />
        <meta property="twitter:title" content={t("key24493", "Моля изчакайте...")} />
      </Helmet>
    );
  }

  if (!pageProps && isNoIndex) {
    return (
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="noindex, nofollow" />
      </Helmet>
    );
  }

  // Case for statick SSR build
  if (!pageProps) {
    return;
  }

  const metaDescription = pageProps?.description || defaults?.description;
  const defaultTitle = pageProps?.title || defaults?.title;
  const lang = props.lang || defaults.lang;
  const ldSchemal = props.schemaMarkup || pageProps?.schemaMarkup;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={pageProps.title}
      titleTemplate={defaultTitle}
    >
      {pageProps.canonical && <link rel="canonical" href={pageProps.canonical === "/" ? companyURL : `${companyURL}${pageProps.canonical}`} />}
      {pageProps.canonical && <meta property="og:url" content={`${companyURL}${pageProps.canonical === "/" ? "" : pageProps.canonical}`} />}
      <meta property="og:locale" content="bg_BG" />
      <meta property="og:site_name" content={websiteName || ""} />
      <meta name="keywords" content={pageProps.keywords || ""} />
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={pageProps.title || ""} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={pageProps.image ? `${companyURL}images/${pageProps.image}` : ""} />

      {showMetaDataAndSchemas && <meta property="twitter:card" content="summary" />}
      {showMetaDataAndSchemas && <meta property="twitter:title" content={pageProps.title || ""} />}
      {showMetaDataAndSchemas && <meta property="twitter:description" content={metaDescription} />}
      {showMetaDataAndSchemas && <meta property="twitter:image" content={pageProps.image ? `${companyURL}images/${pageProps.image}` : ""} />}
      {showMetaDataAndSchemas && <meta name="apple-itunes-app" content="app-id=1476539247" />}
      {showMetaDataAndSchemas && <meta property="fb:app_id" content="431182437575375" />}

      <link rel="dns-prefetch" href="https://fonts.googleapis.com" />

      {isNoIndex && <meta data-react-helmet="true" name="robots" content="noindex, nofollow" />}
      {showMetaDataAndSchemas && ldSchemal && <script type="application/ld+json">{JSON.stringify(ldSchemal)}</script>}
    </Helmet>
  );
};

SeoTenant.defaultProps = {
  lang: `bg`,
  meta: [],
  description: ``
};

export default SeoTenant;
