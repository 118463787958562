import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Trans } from "gatsby-plugin-react-i18next";

import LazySVGS from "../../images/LazySVGS";

const propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  subType: PropTypes.string,
  classes: PropTypes.string,
  isSvgDirectLoaded: PropTypes.bool,
  componentStyle: PropTypes.oneOfType([PropTypes.object]),
  svgIcon: PropTypes.string,
  iconSize: PropTypes.number,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const Notifications = ({
  classes,
  componentStyle,
  type,
  subType,
  message = "",
  textColor,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  maxWidth,
  maxHeight,
  iconSize,
  svgIcon,
  isSvgDirectLoaded = true
}) => {
  PropTypes.checkPropTypes(
    propTypes,
    { type, componentStyle, subType, message, textColor, marginTop, marginBottom, marginLeft, marginRight, maxWidth, maxHeight, isSvgDirectLoaded },
    "prop",
    "Notifications"
  );

  let isThereRichText = null;
  let parts = [];

  if ((!message || typeof message !== "string") && !Array.isArray(message)) {
    return null;
  }

  let messageContent = message;
  if (!Array.isArray(message)) {
    if (message && message.includes("pages.common")) {
      messageContent = <Trans i18nKey={message} />;
    } else {
      parts = messageContent.split(/(<strong>[^<]*<\/strong>)|(<hr>)|(<arrows>[^<]*<\/arrows>)|(<br\s\/>)/);
      isThereRichText = Array.isArray(parts) ? parts.length !== 1 : null;
    }
  }

  return (
    // Type and subType -COLORS- are coming from the bulma helpers -> https://bulma.io/documentation/overview/colors/
    // Note that when adding a new color add it to svg.sass for the icon to support it --> Example : .is-primary is from bulma and we add .is-primary-svg to svg.sass
    <Suspense fallback={null}>
      <div className={`notification ${classes} ${type} ${subType}`} style={{ marginTop, marginBottom, marginLeft, marginRight, maxWidth, maxHeight, ...componentStyle }}>
        {svgIcon && (
          <div>
            <LazySVGS svg={svgIcon} className={`${type}-svg`} width={iconSize} height={iconSize} directLoad={isSvgDirectLoaded} />
          </div>
        )}

        <div>
          {isThereRichText
            ? parts.map((part, i) => {
                if (part !== undefined) {
                  if (part.startsWith("<strong>")) {
                    return (
                      <React.Fragment key={i}>
                        <strong>{part.slice(8, -9)}</strong>
                      </React.Fragment>
                    );
                  }
                  if (part.startsWith("<hr>")) {
                    return (
                      <React.Fragment key={i}>
                        <hr style={{ backgroundColor: "#DCDCDC", height: 1, margin: "5px 0" }} />
                      </React.Fragment>
                    );
                  }
                  if (part.startsWith("<arrows>")) {
                    return (
                      <div className="is-flex is-align-items-baseline" style={{ gap: 15, marginTop: 15, marginLeft: "-1.6rem", marginRight: "-1.6rem" }} key={i}>
                        <LazySVGS svg="DoubleArrow" className={`${type}-svg`} style={{ minHeight: 11, minWidth: 12 }} />
                        <p className="is-size-5 is-regular-font-family">{part.slice(8, -9)}</p>
                      </div>
                    );
                  }
                  if (part.startsWith("<br />")) {
                    return <br key={i} />;
                  }
                  return <React.Fragment key={i}>{part}</React.Fragment>;
                }
                return "";
              })
            : messageContent}
        </div>
      </div>
    </Suspense>
  );
};

export default Notifications;

Notifications.defaultProps = {
  subType: "",
  classes: "",
  svgIcon: "",
  isSvgDirectLoaded: true,
  iconSize: 22,
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  maxWidth: "",
  maxHeight: "",
  componentStyle: {}
};

Notifications.propTypes = propTypes;
